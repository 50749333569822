import { Row } from 'react-bootstrap';
import { PelletTestCol } from './pelletTestCol';
import _ from 'lodash';
function GetTitle(title)
{
  let testWordIndex = 0;
  while (testWordIndex < title.length)
  {
    ++testWordIndex;
    if (title[testWordIndex] === title[testWordIndex].toUpperCase())
      break;
  }

  if (testWordIndex === title.length) return title;
  else
  {
    let firstWord = title.substring(0,testWordIndex);
    let secondWord = title.substring(testWordIndex,title.length);
    return firstWord + ' ' + secondWord;
  }
}


export function PelletTestViewRow(props) {
  const data = props.data; //Data as key value pairs
  const units = props.units??[];
  const title = GetTitle(props.title??'');
  const null_keys = Object.values(data).find((value)=>_.isNull(value)) !== undefined;

  return (
    <Row
      as={'fieldset'}
      disabled={null_keys || !props.editing}
      style={{
        opacity: null_keys ? '65%' : '100%',
      }}
      key={props.myKey}
      className={props.className}
      name={props.name ?? ''}
      onChange={props.onChange}

    >
      <h3 style={{textTransform:'capitalize'}}>{title}</h3>

        {
        Object.entries(props.data).map((keyValuePair,index) => {
          return (
            <PelletTestCol
            myKey={keyValuePair[0]}
            unit={units[index]}
            name={keyValuePair[0]}
            defaultValue={keyValuePair[1]}
            title={keyValuePair[0].replace(/_/g,' ')}
            readOnly={index>1?true:!props.editing}
            disabled={index>1?true:!props.editing}
            
            />
          );
        })}
      
    </Row>
  );
}
