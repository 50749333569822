import { useState } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';
import { PelletTestViewRow } from '../c_components/pelletTestViewRow';
import _ from 'lodash';
import { UpdatePelletTest } from '../c_helpers/apiCaller';

const units = [
  ["g","g",(<>g/m<sup>3</sup></>)],
  ["g","g","%"],
  ["Pellets","mm","mm"],
  ["g","g","%"],
  ["g","%"],
  ["g","%"]
]

function setDataDelegate(data,settingFunction, changedData, fieldsetName, defaultData)
{
  let newData = data;
  newData[fieldsetName][changedData.name] = parseFloat(changedData.value); //Value is always string since inputs provide strings
  settingFunction(newData)

  // console.log("Default Data:");
  // console.log(defaultData);
  // console.log("New Data:");
  // console.log(newData);
}

export function PelletTestView() {
  // const loaderData =;
  const [changes, setChanges] = useState({});
  const defaultData = useLoaderData();
  const [data, setData] = useState(structuredClone(useLoaderData()));
  const [editing, setIsEditing] = useState();
  return (
    <>
    <Modal show={!(_.isEmpty(changes) && _.isEqual(data,defaultData))} onHide={()=>{setData(defaultData); setChanges({}); }}>
      <Modal.Header closeButton >
        <Modal.Title>
        Save Changes?
        </Modal.Title>
        </Modal.Header>
      <Modal.Body>
        {
          //TODO: Loop through changes.
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{UpdatePelletTest(changes)}} disabled={_.isEmpty(changes) || _.isEqual(data,defaultData)}>Confirm</Button>
      </Modal.Footer>
    </Modal>
      <Form
        onSubmit={(form) => {
          form.preventDefault();
          const changes = onSubmit(data, defaultData);
          setChanges(changes);

        }}
      >
        <input hidden name="test_id" value={defaultData.pelletTest.test_id} />
        <Row>
          <Col xs={'auto'}>
            <h3>
              Test ID {defaultData.pelletTest.test_id ?? ''} | Submitted by{' '}
              {defaultData.pelletTest.tester_id ?? ''} |{' '}
              {new Date(defaultData.pelletTest.created_at).toLocaleDateString()}{' '}
              {' - '}
              {new Date(defaultData.pelletTest.created_at)
                .toLocaleTimeString()
                .substring(0, 5)}
            </h3>
            <small>Sample ID: {defaultData.pelletTest.sample_id}</small>
          </Col>
          <Col style={{textAlign:"right"}}>
            <Button
              onClick={() => {
                setIsEditing(!editing);
              }}
              type={editing ? 'button' : 'reset'}
              style={{minWidth:60}}
              className='me-2'
              variant={editing?"danger":"primary"}
            >
              {editing ? 'Cancel' : 'Edit'}
            </Button>
            <Button 
            disabled={!editing} 
            type="submit"
            style={{minWidth:60}}
              variant={editing?"success":"primary"}
            >
              Save
            </Button>
          </Col>
        </Row>

        {
        Object.entries(defaultData).map((fieldset, index) => {
          if (fieldset[0] === 'pelletTest') return null;
          //Data structure: ['testName',{...}]
          let name = fieldset[0];
          let title = fieldset[0];
          if (fieldset[0].includes("Alt") || fieldset[0].includes("alt"))
              title = "Moisture Test (Oven)";
          const localData = fieldset[1];

          return (
            <PelletTestViewRow
              units={units[index-1]}
              name={name}
              myKey={name}
              data={localData}
              title={title}
              editing={editing}
              onChange={(e)=>{
                setDataDelegate(data,setData,
                  {
                    name:e.target.name,
                    value:e.target.value
                  },
                  name,
                  defaultData
                )}}
            />
          );
        })}

      </Form>
      {
        'externalTest' in defaultData
        ?""
        :(
        <Row>
        <Col>
          <Button disabled>Add External Test</Button>
        </Col>
      </Row>)
      }
    </>
  );
}

async function onSubmit(data, defaultData) {

  let changesPayload = {
    test_id:defaultData.pelletTest.test_id,
  }
  //Add data points if there is a difference.
  if (!_.isEqual(data.bulkDensity, defaultData.bulkDensity))
    changesPayload.bulkTest = data.bulkDensity;
  if (!_.isEqual(data.fineTest, defaultData.fineTest))
    changesPayload.fineTest = data.fineTest;
  if (!_.isEqual(data.lengthTest, defaultData.lengthTest))
    changesPayload.lengthTest = data.lengthTest;
    if (!_.isEqual(data.moistureTest, defaultData.moistureTest))
    changesPayload.moistureTest = data.moistureTest;
    if (!_.isEqual(data.moistureTestAlt, defaultData.moistureTestAlt))
    changesPayload.moistureTestAlt = data.moistureTestAlt;
  if (!_.isEqual(data.mechanicalTest, defaultData.mechanicalTest))
    changesPayload.mechanicalTest = data.mechanicalTest;

  return changesPayload;

}
