import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

//Pages
import { NewPelletTest } from './c_pages/new_pellet_test';
import { PelletTestView } from './c_pages/pellet_test_view';
import SecureLayout from './c_layouts/secureLayout';
import { WeeklyReport } from './c_pages/reports/week_report';
import LoginPage from './c_pages/login_page';
import { Error404Page } from './c_pages/error404';

//CSS
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//MS Auth
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './c_helpers/authConfig';

//API Helpers
import {
  GetTestById,
  GetDailyAverages,
  CheckIsLive,
  GetPelletTests,
} from './c_helpers/apiCaller';
//import PelletTestList from './c_pages/pellet_test_list';
import PelletTestListFunction from './c_pages/pellet_test_list_function';

//MS Auth Instance
const msalInstance = new PublicClientApplication(msalConfig);
//Browser Router
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route exact path="dash" element={<SecureLayout />}>
        <Route
          exact
          path=""
          index
          element={<PelletTestListFunction />}
          loader={({request})=>{
            return GetPelletTests(request);
          }}
          errorElement={<Error404Page data={{statusText:"Couldn't reach server. Please contact IT if this continues.", status:500}} />}
        />
        <Route
          path="pellet-test/:id"
          element={<PelletTestView />}
          loader={async ({ params }) => {
            let res = await GetTestById(params.id);
            if (res.status !== 200) throw new Response("",res);
            else
            return res;
          }}
          errorElement={<Error404Page />}
        />
        <Route
          path="new-test"
          element={<NewPelletTest />}
          loader={({request})=>{
            return CheckIsLive();
          }}
          errorElement={<Error404Page data={{status:500, statusText:"Could not reach server, please try again in a bit. If this continues, please contact IT."}} />}
        />
        <Route
          path="reports/week"
          element={<WeeklyReport />}
          loader={async ({ request }) => GetDailyAverages(request)}
          shouldRevalidate={({ currentUrl, currentParams, nextParams }) => {
            return (
              currentUrl.pathname === '/dash/reports/week' &&
              nextParams !== currentParams
            );
          }}
          errorElement={<Error404Page />}
        />
        <Route path='*' 
          element={<Error404Page data={{status:404,statusText:"The requested page does not exist."}} />}
          />
      </Route>

      <Route index exact path="" element={<LoginPage />} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
