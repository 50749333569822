import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import {SignInButton} from "../c_components/loginButton"
import Logo from "../images/biofuel_logo.png";
export default class LoginPage extends Component
{

    render()
    {
        
        return(
            <Container>

        <Row>
            <UnauthenticatedTemplate>
                <Col style={{justifyContent:"center", alignItems:"center",display:"flex", flexDirection:"column", top:"25%",position:"absolute",left:0,right:0}}>
                <img src={Logo} alt="Biofuel Energy Logo" width={200} className="mb-3"/>
                <SignInButton/>
                </Col>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Navigate to="/dash" />
            </AuthenticatedTemplate>
        </Row>
            </Container>
        )
    }
}