import { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { SignOutButton } from "./logoutButton";

import Logo from '../images/biofuel_logo.png';
export default class MainNav extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            accountName:props.username
        }
    }

    render()
    {
        return(
            <>
        <Navbar expand={'md'} collapseOnSelect className="mb-3">
            <Navbar.Brand>            
            <img
              alt=""
              src={Logo}
              height="70"
              className="d-inline-block align-top"
            />{' '} Pellet Testing</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav className="me-auto">
                    <Nav.Link href="/dash">Home</Nav.Link>
                    <NavDropdown title="New Test">
                        <NavDropdown.Item href="/dash/new-test">Internal</NavDropdown.Item>
                        <NavDropdown.Item href="/dash/external-test">External</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Reports">
                        <NavDropdown.Item href="/dash/reports/week">Weekly</NavDropdown.Item>
                        <NavDropdown.Item href="/dash/reports/month">Monthly</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/dash/standards">Standards</Nav.Link>
                </Nav>
                <Nav className="mx-auto">Hi, {this.state.accountName}!</Nav>
                <Nav className="mt-2">
                    <SignOutButton/>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <hr/>

            </>
        );
    }
}