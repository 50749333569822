

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CID,
        authority: (new URL(process.env.REACT_APP_MSAL_TENANT_ID, process.env.REACT_APP_MSAL_AUTHORITY)).toString(),
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT
    },
    cache:{
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie:false
    }
};

export const loginRequest = {
    scopes:["User.Read"]
};
