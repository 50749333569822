import { useState } from 'react';
import {
  Table,
  InputGroup,
  Form,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Pagination,
  Button,
  Stack,
} from 'react-bootstrap';
import {
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { FormatDate } from '../c_helpers/apiCaller';
import '../css/home_page.css';
import _ from 'lodash';
export default function PelletTestListFunction() {
  //Navigate to specific pellet test page.
  const navigate = useNavigate();
  //Loader data for pellet test.
  const tableDataString = useLoaderData()?? [];
  const tableData = JSON.parse(tableDataString);
  //Search Params
  const [searchParams, setSearchParams] = useSearchParams();
  //Date.now() but is mutable. Date.now() alone breaks when formatting.
  const MUTABLE_NOW = new Date(Date.now());
  //Date.now() formatted.
  const FORMATTED_NOW = FormatDate(MUTABLE_NOW);

  //Limit results per page.
  const [LIMIT, setLimit] = useState(25);
  //Change pages using state without reloading data.
  const [pageNo, setPageNo] = useState(0);
  //Max page number based on results.
  const MAX_PAGES = Math.floor( tableData.length / LIMIT);

  console.log(`Count: ${tableData.length}, Limit: ${LIMIT}, Page: ${pageNo} of ${MAX_PAGES}`)

  function handleSubmit(event) {
    event.preventDefault();
    const elements = event.target.elements;

    let fromDate = elements["fromDate"]?.value || null;
    let toDate = elements["toDate"]?.value || null;
    let searchQuery = elements["search"]?.value || null;
    

    let params = {
      fromDate:fromDate,
      toDate:toDate
    }
    if (!_.isEmpty(searchQuery))
    {
      Object.assign(params,
      {
        search: searchQuery
      });
        
    }
    setSearchParams(params);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={5} xs={12}>
            <InputGroup>
              <InputGroup.Text>Search:</InputGroup.Text>
              <Form.Control
                placeholder="Tester/Test Id"
                name="search"
                defaultValue={searchParams.get('search') ?? ''}
              />
            </InputGroup>
          </Col>
          <Col md={5} xs={12}>
            <InputGroup className="mb-3">
              <InputGroup.Text>Date Range</InputGroup.Text>
              <Form.Control
                type="date"
                aria-label="From Date"
                defaultValue={
                  searchParams.get('fromDate') ??
                  FormatDate(MUTABLE_NOW - 1000 * 60 * 60 * 24 * 30)
                }
                max={searchParams.get('toDate') ?? FORMATTED_NOW}
                name="fromDate"
              />
              <Form.Control
                type="date"
                aria-label="To Date"
                defaultValue={searchParams.get('toDate') ?? FORMATTED_NOW}
                min={
                  searchParams.get('fromDate') ??
                  FormatDate(MUTABLE_NOW - 1000 * 60 * 60 * 24 * 30)
                }
                max={FORMATTED_NOW}
                name="toDate"
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={2} style={{textAlign:"right"}}>
          <Button className="ms-auto" type="submit">
                Search
              </Button>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col as={'fieldset'} name="toggles">
            <legend>Filters</legend>
            <Stack direction="horizontal" gap={3}>
              <DropdownButton
                variant="outline-secondary"
                title={`Toggle Tests`}
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                autoClose="outside"
                disabled
              >
                <Dropdown.Item eventKey={'density'}>
                  <Form.Check
                    defaultChecked={searchParams.get('densityTest') ?? false}
                    label="Density Test"
                    name="densityTest"
                  />
                </Dropdown.Item>
                <Dropdown.Item eventKey={'fine'}>
                  <Form.Check
                    name="fineTest"
                    checked={searchParams.get('fineTest') ?? false}
                    label="Fine Test"
                  />
                </Dropdown.Item>
                <Dropdown.Item eventKey={'length'}>
                  <Form.Check
                    checked={searchParams.get('lengthTest') ?? false}
                    label="Length Test"
                    name="lengthTest"
                  />
                </Dropdown.Item>
                <Dropdown.Item eventKey={'mecha'}>
                  <Form.Check
                    checked={searchParams.get('mechaTest') ?? false}
                    label="Mechanical Test"
                    name="mechaTest"
                  />
                </Dropdown.Item>
                <Dropdown.Item eventKey={'moisture'}>
                  <Form.Check
                    checked={searchParams.get('moistureTest') ?? false}
                    label="Moisture Test"
                    name="moistureTest"
                  />
                </Dropdown.Item>
                <Dropdown.Item eventKey={'moistureAlt'}>
                  <Form.Check
                    checked={searchParams.get('moistureTestAlt') ?? false}
                    label="Moisture Alt Test"
                    name="moistureTestAlt"
                  />
                </Dropdown.Item>
                <Dropdown.Item eventKey={'external'}>
                  <Form.Check
                    checked={searchParams.get('externalTest') ?? false}
                    label="External Test"
                    name="externalTest"
                  />
                </Dropdown.Item>
                <Dropdown.Divider />
                {/* <Dropdown.Item eventKey={'all'} style={{ textAlign: 'center' }}>
              Toggle All
            </Dropdown.Item> */}
              </DropdownButton>
              <DropdownButton
                variant="outline-secondary"
                title={`Limit (${LIMIT})`}
                onSelect={(eventKey)=>{setLimit(eventKey)}}
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
              >
                <Dropdown.Item eventKey={25}>25</Dropdown.Item>
                <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                <Dropdown.Item eventKey={100}>100</Dropdown.Item>
              </DropdownButton>
              <Button href="/dash/new-test" className='ms-auto'>New Test</Button>
              <Button disabled>Export Data</Button>

            </Stack>
          </Col>
        </Row>
      </form>
      <Row>
        <Table striped bordered hover responsive="lg" id="pellet-test-list">
          <thead>
            <tr>
              <th>
                <span>Date</span>
              </th>
              <th>
                <span>Tester</span>
              </th>
              <th>
                <span>Sample Id</span>
              </th>
              <th>
                <span>Density Test</span>
              </th>
              <th>
                <span>Fine Test</span>
              </th>
              <th>
                <span>Length Test</span>
              </th>
              <th>
                <span>Mechanical Test</span>
              </th>
              <th>
                <span>Moisture Test</span>
              </th>
              <th>
                <span>Moisture Test (Other)</span>
              </th>
              <th>
                <span>Ext. Test</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {this.state.tableError ? (
              <tr id="error-row">
                <td colSpan={10}>
                  Error fetching pellet tests. Please contact IT if error
                  continues.
                </td>
              </tr>
            ) : ( */}
            {
              _.slice(
                tableData,
                pageNo * LIMIT,
                pageNo * LIMIT + LIMIT
                )
                .map((result) => {
                  return (
                    <tr
                      key={result.test_id}
                      onClick={() => {
                        navigate(`/dash/pellet-test/${result.test_id}`)
                      }}
                    >
                      <td>
                        {new Date(result.created_at).toLocaleDateString() ??
                          '-'}
                      </td>
                      <td>{result.tester_id ?? '-'}</td>
                      <td>{result.sample_id ?? '-'}</td>
                      <td className={(result.density_test && !result.density_test_pass)?'table-danger':''}>{result.density_test ? '✔️' : '-'}</td>
                      <td className={(result.fine_test && !result.fine_test_pass)?'table-danger':''}>{result.fine_test ? '✔️' : '-'}</td>
                      <td className={(result.length_test && !result.length_test_pass)?'table-danger':''}>{result.length_test ? '✔️' : '-'}</td>
                      <td className={(result.mecha_test && !result.mecha_test_pass)?'table-danger':''}>{result.mecha_test ? '✔️' : '-'}</td>
                      <td className={(result.moisture_test && !result.moisture_test_pass)?'table-danger':''}>{result.moisture_test ? '✔️' : '-'}</td>
                      <td className={(result.moisture_test_alt && !result.moisture_alt_test_pass)?'table-danger':''}>{result.moisture_test_alt ? '✔️' : '-'}</td>
                      <td>{result.external_test ? '✔️' : '-'}</td>
                    </tr>
                  );
                })}
            
          </tbody>
        </Table>
      </Row>

      <Row>
        <Col>
          <Pagination style={{ justifyContent: 'center' }}>
            <Pagination.First
              disabled={pageNo <= 0}
              onClick={() => {
                setPageNo(0);
              }}
            />
            <Pagination.Prev
              disabled={pageNo <= 0}
              onClick={() => {
                setPageNo(pageNo - 1);
              }}
            />
            <Pagination.Item disabled>
              {pageNo + 1}
            </Pagination.Item>
            <Pagination.Next
              disabled={pageNo >= MAX_PAGES}
              onClick={() => {
                setPageNo(pageNo + 1);
              }}
            />
            <Pagination.Last
              disabled={pageNo >= MAX_PAGES}
              onClick={() => {
                setPageNo(MAX_PAGES);
              }}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
